import { mapGetters } from 'vuex';
import globalTypes from '@/types/global';
import precomproTypes from '@/types/precompro';
import _ from 'lodash';
import LCrypt from 'lcrypt';
const lcrypt = new LCrypt(process.env.VUE_APP_TOKEN);
export default {
  computed: {
    ...mapGetters({
      getSelectedDate: [globalTypes.getters.selectedDate],
      getHeight: [globalTypes.getters.height],
      getWidth: [globalTypes.getters.width],
      getIsMobile: [globalTypes.getters.isMobile],
      getIsLanguage: [globalTypes.getters.language],
      getOnline: [globalTypes.getters.online],
      getPeople: [precomproTypes.getters.reservationPeople],
      getHours: [precomproTypes.getters.reservationHours],
      getDate: [precomproTypes.getters.reservationDate],
      getShowSearch: [precomproTypes.getters.reservationShowSearchButton],
      getShowHours: [precomproTypes.getters.reservationShowHours],
      getShowProcessing: [precomproTypes.getters.reservationShowProcessingHours],
      getSearch: [precomproTypes.getters.reservationSearch],
      getSearchingAvailability: [precomproTypes.getters.reservationSearchingAvailability],
      getAvailability: [precomproTypes.getters.reservationAvailability],
      getRecommended: [precomproTypes.getters.reservationRecommended],
      vendors: [precomproTypes.getters.reservationVendors],
      getShowVendors: [precomproTypes.getters.showVendors],
      vendor: [precomproTypes.getters.vendor],
      gallery: [precomproTypes.getters.gallery],
      services: [precomproTypes.getters.services],
      source: [precomproTypes.getters.source],
      reservation: [precomproTypes.getters.reservation],
      paymentType: [precomproTypes.getters.paymentType],
      loader: [precomproTypes.getters.loader],
      banks: [precomproTypes.getters.banks],
      profileTab: [precomproTypes.getters.profileTab],
      reservationByUser: [precomproTypes.getters.reservationByUser],
      politicaConfirmationModal: [precomproTypes.getters.showModalPolitica],
      confirmationReservationModal: [precomproTypes.getters.showModalReservationConfirm],
      editReservation: [precomproTypes.getters.editReservation],
      showAddPay: [precomproTypes.getters.showAddPay],
      getTimeAtention: [precomproTypes.getters.reservationTimeAtention],
      sendReservation: [precomproTypes.getters.sendReservation],
      selectHourOk: [precomproTypes.getters.selectHourOk],
      showModal: [precomproTypes.getters.showModal]
    }),
    vendorId () {
      return this.vendor.id;
    },
    showVendors () {
      return this.getShowVendors;
    },
    recommended () {
      return this.getRecommended;
    },
    availability () {
      return this.getAvailability;
    },
    searchingAvailability () {
      return this.getSearchingAvailability;
    },
    search () {
      return this.getSearch;
    },
    processingHours () {
      return this.getShowProcessing;
    },
    timeAtention () {
      return this.getTimeAtention;
    },
    showSearch () {
      return this.getShowSearch;
    },
    showHours () {
      return this.getShowHours;
    },
    people () {
      return this.getPeople;
    },
    date () {
      return this.getDate;
    },
    dateReservation () {
      return this.getHours;
    },
    getStatusOnline () {
      return this.getOnline;
    },
    idUser () {
      return this.getUserId;
    },
    icons () {
      return '/favicon.png';
    },
    url () {
      return 'https://precompro.com';
    },
    titles () {
      return 'Precompro.com - Reserva en tus restaurantes favoritos';
    },
    description () {
      return 'Reserva en los mejores restaurantes de Colombia con la disponibilidad real y confirmación inmediata. Asegura tu reserva ahora. ¡Fácil, rápido y seguro!';
    },
    keyw () {
      return 'mejores restaurantes Bogotá, mejores restaurantes Cartagena, mejores restaurantes de Colombia, restaurantes, 50 best latín América, El chato, La Pinta, restaurantes DLK, Gitane, Bistecca e vino, Trattoria de la plaza, Primi, Casa, Agadon, Gamberro, Di lucca, Leon Mozzarella, Vitto, La Toscana, Llorente, Lorenzo el griego, Tomodachi, Emilia Grace, Taperia de la plaza, Huerta, Oak, El Santisimo, Nueve, Celele, Gula, Misia, Harry Sasson, Gaspar, El Santo del angel, Gaira Café';
    },
    getDataUser () {
      return this.getUser;
    },
    selectedDate () {
      return this.getSelectedDate;
    },
    getStateMobile () {
      return this.getIsMobile;
    },
    getStateWidth () {
      return this.getWidth;
    },
    getStateHeight () {
      return this.getHeight;
    },
    getStateOrientation () {
      return this.getIsOrientation;
    },
    getStateLanguage () {
      return this.getIsLanguage;
    }
  },
  methods: {
    monthConvert (month) {
      if (month === 1) {
        return 0;
      } else {
        return month - 1;
      }
    },
    showStatus (data) {
      this.$store.commit(globalTypes.mutations.setOnline, data);
    },
    getInitials (name) {
      return _.upperCase(_((name || '').split(' '))
        .filter(text => text)
        .map(text => text[0])
        .take(2).value()
        .join(''));
    },
    getMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.commit(globalTypes.mutations.setIsMobile, true);
      } else {
        this.$store.commit(globalTypes.mutations.setIsMobile, false);
      }
    },
    chargueScript (url, async = false) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', url);
      if (async) {
        recaptchaScript.setAttribute('async', 'async');
      }
      document.body.appendChild(recaptchaScript);
    },
    encrypt (value) {
      return lcrypt.encode(value);
    },
    decryptMx (value) {
      return lcrypt.decode(value);
    }
  }
};
