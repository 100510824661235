export default {
  custom: {
    email: {
      required: 'El email es requerido',
      email: 'El correo electrónico no es válido'
    },
    password: {
      required: 'La contraseña es requerido',
      min: 'La contraseña es demasiado corta'
    },
    password_confirmation: {
      required: 'La confirmación es requerida',
      min: 'La contraseña es demasiado corta'
    },
    displayName: {
      required: 'Los nombres son requeridos'
    },
    phone: {
      required: 'El teléfono es requerido',
      numeric: 'Ingrese solo números',
      max: 'Ha superado el límite de caracteres',
      min: 'El valor del campo es requerido'
    },
    identification: {
      required: 'El valor del campo es requerido',
      numeric: 'Solo se permiten números'
    },
    name: {
      required: 'El valor del campo es requerido',
      alpha_spaces: 'Solo se permiten letras'
    },
    card: {
      required: 'Se requiere que ingrese el número de la tarjeta de crédito',
      error: 'Número de tarjeta de crédito no valida',
      credit_card: 'Número de tarjeta de crédito no valida'
    },
    bank: {
      required: 'Por favor, seleccione una entidad bancaria'
    },
    typePeople: {
      required: 'Por favor, seleccione un tipo de persona'
    },
    typeDoc: {
      required: 'Por favor, seleccione un tipo de documento'
    },
    address: {
      required: 'Se requiere la dirección',
      numeric: 'Ingrese solo números'
    },
    mes: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    ano: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    cuota: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    city: {
      required: 'Se requiere que ingrese la ciudad',
      min: 'Minimo de caracteres no permitido'
    },
    phones: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    }
  }
};
