import precomproModule from '@/modules/precompro';
import Api from '@/services/api';
import globalTypes from '@/types/global';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedDate: null
  },
  actions: {
    [globalTypes.actions.getTimeAtention]: ({ commit }, inf) => {
      return new Promise((resolve, reject) => {
        Api.get('groups/timeAtention?date=' + inf.data.date)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  getters: {
    [globalTypes.getters.selectedDate]: (state) => state.selectedDate
  },
  mutations: {
    [globalTypes.mutations.setSelectedDate] (state, date) {
      state.selectedDate = date;
    }
  },
  modules: {
    precomproModule
  }
});
