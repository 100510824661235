<template>
  <div class="navbar-groups">
    <div class="navbar-groups-logo" @click="home">
      <img src="/images/LOGO_dlk.webp" alt="precompro.com" class="navbar-groups-logo-item">
    </div>
    <div class="navbar-groups-menus">
      <a href="/quienes-somos" class="navbar-groups-menus-item">
        Quienes somos
        <div class="active-group" v-if="$route.path == '/quienes-somos'"></div>
      </a>
      <a href="/reservas" class="navbar-groups-menus-item">
        Reservas
        <div class="active-group" v-if="$route.path == '/reservas'"></div>
      </a>
      <a href="/trabaja-con-nosotros" class="navbar-groups-menus-item">
        Trabaje con nosotros
        <div class="active-group" v-if="$route.path == '/trabaja-con-nosotros'"></div>
      </a>
      <a href="/contactanos" class="navbar-groups-menus-item">
        Contáctenos
        <div class="active-group" v-if="$route.path == '/contactanos'"></div>
      </a>
      <a id="burger-group" @click="$emit('burgerShow')" class="navbar-groups-menus-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
          <rect y="16" width="30" height="3" rx="1.5" fill="white"/>
          <rect y="8" width="30" height="3" rx="1.5" fill="white"/>
          <rect width="30" height="3" rx="1.5" fill="white"/>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    home () {
      window.location.href = '/';
    }
  }
};
</script>
<style lang="scss">
.navbar-groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #000000;
  z-index: 9;
  position: fixed;
  &-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    margin-left: 100px;
    cursor: pointer;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-left: 30px;
    }
    @media screen and (max-width: 1023px) {
      margin-left: 20px;
    }
    &-item {
      width: 103.448px;
      height: 30px;
      flex-shrink: 0;
    }
  }
  &-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    gap: 100px;
    margin-right: 100px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      gap: 40px;
      margin-right: 30px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      gap: 30px;
      margin-right: 20px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 20px;
    }
    #burger-group {
      display: none;
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }
    .active-group {
      width: 100%;
      height: 2px;
      flex-shrink: 0;
      border-radius: 1px;
      background: #FFF;
      position: relative;
      bottom: -15px;
    }
    &-item {
      color: #FFF;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        display: none;
      }
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
</style>
