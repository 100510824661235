import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Meta from 'vue-meta';
import Notifications from 'vue-notification';
import VeeValidate, { Validator } from 'vee-validate';
import validatorEs from '@/validator/es';
import ReadMore from 'vue-read-more';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io-extended';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import VueTheMask from 'vue-the-mask';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vue2Filters from 'vue2-filters';
import global from '@/mixins/global';
import vueAwesomeSwiper from 'vue-awesome-swiper';
import VueSweetalert2 from 'vue-sweetalert2';

import 'swiper/swiper.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'moment/locale/es';

Validator.localize('es', validatorEs);

Vue.use(VueSweetalert2);
Vue.use(Vue2Filters);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBEiuJ8FGGFTuBE3qKCw8oFiycGuVVLvSc'
  }
});
Vue.use(VueTheMask);
Vue.use(VueMoment, {
  moment
});
Vue.use(ReadMore);
Vue.use(vueAwesomeSwiper);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.use(VeeValidate);
Vue.use(Meta);
Vue.use(Notifications);
Vue.mixin(global);
Vue.mixin(Vue2Filters.mixin);
if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueSocketIO,
    io('https://socket.precompro.com', {
      autoConnect: true,
      transports: ['websocket'],
      query: {
        vendorId: 0
      }
    })
  );
} else {
  Vue.use(
    VueSocketIO,
    io('https://socket.precompro.co', {
      autoConnect: true,
      transports: ['websocket'],
      query: {
        vendorId: 0
      }
    })
  );
}

Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
