import namespace from '@/utils/namespace';

export default namespace('global', {
  actions: [
    'getTimeAtention'
  ],
  getters: [
    'selectedDate'
  ],
  mutations: [
    'setSelectedDate'
  ]
});
