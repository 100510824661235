import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue')
  },
  {
    path: '/reservas',
    name: 'reservas',
    component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservation.vue')
  },
  {
    path: '/quienes-somos',
    name: 'quienes-somos',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about.vue')
  },
  {
    path: '/trabaja-con-nosotros',
    name: 'trabaja-con-nosotros',
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/contactanos',
    name: 'contactanos',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact.vue')
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: () => import(/* webpackChunkName: "politic" */ '@/views/politic.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
