<template>
  <div id="app">
    <Navbar @burgerShow="showBurgerItem()"></Navbar>
    <Burger v-if="showMenu" @hideBurger="hiddenBurgerItem($event)"></Burger>
    <div class="group-container">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import Navbar from '@/components/Template/Navbar.vue';
import Footer from '@/components/Template/Footer.vue';
import Burger from '@/components/Template/Burger.vue';

export default {
  components: {
    Navbar,
    Footer,
    Burger
  },
  data () {
    return {
      showMenu: false
    };
  },
  mounted () {
    moment.tz.setDefault('America/Bogota');
  },
  methods: {
    showBurgerItem () {
      this.showMenu = !this.showMenu;
    },
    hiddenBurgerItem (url = null) {
      this.showMenu = false;
      if (url != null) {
        window.location.href = url;
      }
    }
  }
};
</script>
<style lang="scss">
html, body, #app {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
.hover-effect {
  animation: scaleUp 0.3s ease forwards;
}
.group-container {
  height: calc(100% - 227px);
  padding-top: 60px;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    height: calc(100% - 207px);
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 213px);
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 176px);
  }
  @media screen and (max-height: 730px) {
    height: auto;
  }
  @media screen and (width: 1280px) and (height: 800px) {
    height: auto;
  }
  @media screen and (width: 414px) and (height: 715px) {
    height: calc(100% - 176px);
  }
}
</style>
